<script setup>
import { onMounted, ref } from "vue";
import { getDataObjectById } from 'o365-dataobject';
import { procSignSJA } from 'sja.vue.libraries.modules.utilities.js'
import SignaturePad from 'sja.vue.libraries.modules.signaturePad.js'
 
const props = defineProps({
    sjaID: Number,
    personRow: Object
});

let signaturePad = null;
let isSignature = ref(false)

const dsParticipants = getDataObjectById(`dsParticipants_${props.sjaID}`)

onMounted(() => {
    const canvas = document.getElementById('sign-pad-canvas');
    signaturePad = new SignaturePad(canvas, {
        minWidth: 1,
        maxWidth: 4,
        backgroundColor: "rgb(255,255,255)"
    });
    signaturePad.addEventListener("endStroke", () => {
        isSignature.value = signaturePad.isEmpty() ? false : true;
    });
});

function clearSign() {
    signaturePad.clear();
    isSignature.value = false
};

function undoSign() {
    var data = signaturePad.toData();

    if (data) {
        data.pop();
        signaturePad.fromData(data);
        if(data.length < 1){
            isSignature.value = false
        }
    }
};

async function saveSign(row) {
    console.log(row)
    let signature = signaturePad.toDataURL('image/png');
    var imgBin = atob(signature.split(',')[1]);
    var mimeString = signature.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(imgBin.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < imgBin.length; i++) {
        ia[i] = imgBin.charCodeAt(i);
    }
    var imgBlob = new Blob([ab], { type: mimeString });
    let vOptions = {
        files: [imgBlob]
    };
    let pData = {
        PrimKey: row.PrimKey
        }
    try {
        await procSignSJA.execute({ ParticipantKey: row.PrimKey });
        await dsParticipants.fileUpload.upload(vOptions, pData).then(() => dsParticipants.load());
    }
    finally {
        clearSign();
    }
}

</script>

<template>
    <div>
        <canvas id="sign-pad-canvas" class="border" width="400" height="200"></canvas> 
        <div class="d-flex justify-content-end">
            <button class="btn btn-outline-primary" @click="clearSign()">{{$t('Clear')}}</button>
            <button class="mx-1 btn btn-outline-secondary" @click="undoSign()">{{$t('Undo')}}</button>
            <button type="button" class="btn btn-primary" :disabled="!isSignature" @click="saveSign(props.personRow)"
                data-bs-dismiss="modal">{{$t('Sign')}}</button>
        </div>
    </div>
</template>

